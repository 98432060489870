<template>
  <div id="app">
    <Countdown :date="new Date('2022-01-28T16:15:00+0000')" :beauty="beauty"/>
  </div>
</template>

<script>
import Countdown from './components/Countdown.vue'
import eventBus from "@/eventBus";
import 'typeface-roboto/index.css';

export default {
  name: 'App',
  data() {
    return {
      showPicture: false
    }
  },
  computed: {
    beauty() {
      return this.showPicture
    }
  },
  components: {
    Countdown
  },
  mounted() {
    console.log(this)
    eventBus.$on('konami', () => {
      this.showPicture = !this.showPicture
      console.log("showPicture", this.showPicture)
    })
  },
  beforeDestroy() {
    eventBus.$off('konami')
  }
}
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
  user-select: none;
}
canvas{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
