<template>
  <div v-on:click="toggle" v-bind:class="{beautiful: beauty}" class="countdown">
    <div class="tint"></div>
    <div class="panel">
      <div class="digit">
        <div class="digit-number">
          {{ duration.days.toString().padStart(2,"0") }}
        </div>
        <div class="digit-label">
          days
        </div>
      </div>
      <div class="digit">
        <div class="digit-number">
          {{ duration.hours.toString().padStart(2,"0") }}
        </div>
        <div class="digit-label">
          hours
        </div>
      </div>
      <div class="digit">
        <div class="digit-number">
          {{ duration.minutes.toString().padStart(2,"0") }}
        </div>
        <div class="digit-label">
          minutes
        </div>
      </div>
      <div class="digit">
        <div class="digit-number">
          {{ duration.seconds.toString().padStart(2,"0") }}
        </div>
        <div class="digit-label">
          seconds
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intervalToDuration } from 'date-fns'

export default {
  name: 'Countdown',
  data() {
    return {
      now: new Date(),
      timer: null
    }
  },
  props: {
    date: {
      type: Date,
      default() { new Date() }
    },
    negative: {
      type: Boolean,
      default: false
    },
    beauty: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    toggle(){
      this.showPicture = !this.showPicture
      console.log("Happy")
    }
  },
  computed:{
    duration() {
      return intervalToDuration({
        start: this.now, end: this.date
      })
    }
  },
  watch: {
    date:{
      immediate: true,
      handler(newVal) {
        if (this.timer){
          clearInterval(this.timer)
        }
        this.timer = setInterval(() => {
          this.now = new Date()
          if (this.negative) return
          if (this.now > newVal) {
            this.now = newVal
            this.$emit('endTime')
            clearInterval(this.timer)
          }
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.countdown {
  font-size: 14px;
  width: 100%;
  height: 100%;

}
.beautiful.countdown {
  background-image: url("../assets/rau.jpg");
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.panel {
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 3vh;
}
.beautiful .panel {
  height: unset;
  padding: 10px 0;
  bottom: 0;
  background: rgba(44, 62, 80, 0.62);
  margin-bottom: 30px;
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.7);
}
.digit {
  color: black;
  box-sizing: border-box;
  text-align: center;
  height: 25%;
}
.beautiful .digit {
  color: lightpink;
  display: inline-block;
  text-shadow: 1px 1px 2px black, -1px -1px 2px black, -1px 1px 2px black, 1px -1px 2px black;
}
.digit-number {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 10vh;
}
.beautiful .digit-number {
  font-size: 107px;
  font-weight: 300;
  padding: 0 24px;
}
.digit-label {
  text-transform: uppercase;
  font-size: 5vh;
}
.beautiful .digit-label {
  padding-bottom: 5px;
  font-size: 23px;
}

@media (prefers-color-scheme: dark) {
  .panel {
    background-color: black;
    color: white;
  }

  .digit {
    color: white;
  }

}

@media only screen
and (min-device-width: 1024px) {
  .tint {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
  }
}

@media only screen
and (min-device-width: 360px)
and (max-device-width: 926px)
and (-webkit-min-device-pixel-ratio: 3) {

  .digit-number {
    font-family: Roboto, sans-serif;
    font-weight: 300;
  }
  .beautiful .digit-number {
    font-size: 2.7em;
    padding: 0 24px;
  }
  .digit-label {
    text-transform: uppercase;
  }
  .beautiful .digit-label {
    padding-bottom: 5px;
    font-size: 1.1em;
  }
}
</style>
