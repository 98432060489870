const install = function (Vue, options) {
    if (typeof options !== 'undefined' && typeof options.callback !== 'function') {
        return
    }

    document.addEventListener("DOMContentLoaded", () => {

        const code = "upupdowndownleftrightleftrighttaptap";
        let string = "";

        function swipeDetect(el, callback) {
            let touchsurface = el,
                swipedir,
                startX,
                startY,
                distX,
                distY,
                threshold = 100, //required min distance traveled to be considered swipe
                restraint = 150, // maximum distance allowed at the same time in perpendicular direction
                allowedTime = 300, // maximum time allowed to travel that distance
                elapsedTime,
                startTime,
                handleswipe = callback || function () {};

            touchsurface.addEventListener(
                "touchstart",
                function (e) {
                    var touchobj = e.changedTouches[0];
                    swipedir = "tap";
                    startX = touchobj.pageX;
                    startY = touchobj.pageY;
                    startTime = new Date().getTime(); // record time when finger first makes contact with surface
                    e.preventDefault();
                },
                false
            );

            touchsurface.addEventListener(
                "touchmove",
                function (e) {
                    e.preventDefault(); // prevent scrolling when inside DIV
                },
                false
            );

            touchsurface.addEventListener(
                "touchend",
                function (e) {
                    var touchobj = e.changedTouches[0];
                    distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
                    distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
                    elapsedTime = new Date().getTime() - startTime; // get time elapsed
                    if (elapsedTime <= allowedTime) {
                        // first condition for awipe met
                        if (
                            Math.abs(distX) >= threshold &&
                            Math.abs(distY) <= restraint
                        ) {
                            // 2nd condition for horizontal swipe met
                            swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
                        } else if (
                            Math.abs(distY) >= threshold &&
                            Math.abs(distX) <= restraint
                        ) {
                            // 2nd condition for vertical swipe met
                            swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
                        }
                    }
                    console.log(swipedir)
                    handleswipe(swipedir);
                    e.preventDefault();
                },
                false
            );
        }

        const el = document.querySelector("canvas");
        swipeDetect(el, function(dir) {
            //  Log swipes and compare to code.
            string = string.concat(dir);


            if (string.includes(code)) {
                string = ""
                options.callback()
            }
        })
    })

    const kode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'KeyB', 'KeyA']
    const length = kode.length
    var pos = 0
    document.addEventListener('keydown', function (event) {
        console.log(event)
        if (event.code === kode[pos++]) {
            if (length === pos) {
                options.callback()
                pos = 0 // ability to start over
                return false
            }
        } else {
            pos = 0
        }
    }, false)
}

typeof module !== 'undefined' && typeof module.exports !== 'undefined' && (module.exports = install)
