import Vue from 'vue'
import App from './App.vue'
import KonamiCode from './extras/KonamiCode'
import eventBus from "@/eventBus";

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')

Vue.use(KonamiCode, {
    callback: function () {
        eventBus.$emit('konami')
    }
});

